let appName = 'BookTok';

var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
if (isiOS) {
    appName = 'BooksTok'
} else if (isAndroid) {
    appName = 'BookTok'
}

const memberData = {
    'cn': [
        {
            'title': '',
            'content': [
                `歡迎開通${appName} VIP並閱讀《${appName} VIP訂閱服務協議》如果你未滿18周歲，請在法定監護人的陪同下閱讀本協議。`,
            ]
        },
        {
            'title': '一、會員訂閱服務的內容',
            'content': [
                `領取或經付費開啟會員訂閱服務後，你可以在相應服務期限內享有各項專屬特權和增值服務。`,
                `開啟包月會員，包年會員，1個月會員後，您所獲的的服務期限分別為31個自然日，366個自然日，31個自然日。`
            ]
        },
        {
            'title': '二、關於付費服務',
            'content': [
                `你理解並同意，會員訂閱服務為付費服務，${appName}將收取互聯網增值服務使用費；你可以在相關服務頁面查閱會員訂閱服務具體期限及對應費用並自行選擇服務的種類。開啟會員訂閱功能後，無論你是否在相應服務期限內實際使用該服務或訪問電子內容，已支付的費用不支持退款。`,
                `你理解並同意，${appName}有權自行決定並不時修訂會員訂閱服務相關的收費標準和規則，該類標準及規則一經發布即生效，並構成本條款的有效組成部分。如你不同意前述標準、規則及其修訂，你有權停止使用會員訂閱服務。你繼續使用會員訂閱服務即視為你同意相關內容。`,
            ]
        },
        {
            'title': '三、免責聲明',
            'content': [
                `${appName}有權根據產品計劃和運營情況獨立決定會員訂閱服務的具體內容，並有權根據電子內容的版權情況新增或移除電子內容或暫停、終止提供會員訂閱服務的部分服務。前述決定不經通知即生效，你同意${appName}對此免責。`,
            ]
        }
    ],
    'yn': [
        {
            'title': '',
            'content': [
                `Chào mừng bạn mở VIP ${appName} và đọc "Thỏa thuận dịch vụ đăng ký VIP ${appName}" Nếu bạn dưới 18 tuổi, vui lòng đọc thỏa thuận này với người giám hộ hợp pháp của bạn.`,
            ]
        },
        {
            'title': '1. Nội dung của dịch vụ đăng ký thành viên',
            'content': [
                `Sau khi nhận hoặc thanh toán cho dịch vụ đăng ký thành viên, bạn có thể tận hưởng nhiều đặc quyền độc quyền và các dịch vụ giá trị gia tăng trong khoảng thời gian dịch vụ tương ứng.`,
                `Sau khi mở thành viên hàng tháng, thành viên hàng năm và thành viên 1 tháng, thời hạn dịch vụ bạn sẽ nhận được là 31 ngày dương lịch, 366 ngày dương lịch và 31 ngày dương lịch.`,
            ]
        },
        {
            'title': '2. Về dịch vụ trả phí',
            'content': [
                `Bạn hiểu và đồng ý rằng dịch vụ đăng ký thành viên là dịch vụ trả phí và ${appName} sẽ thu phí sử dụng các dịch vụ giá trị gia tăng trên Internet; bạn có thể kiểm tra thời hạn cụ thể và mức phí tương ứng của dịch vụ đăng ký thành viên trên trang dịch vụ liên quan và tự chọn loại dịch vụ. Sau khi chức năng đăng ký thành viên được bật, bất kể bạn thực sự sử dụng dịch vụ hay truy cập nội dung điện tử trong khoảng thời gian dịch vụ tương ứng hay không, phí đã trả sẽ không được hoàn lại.`,
                `Bạn hiểu và đồng ý rằng ${appName} có quyền quyết định và sửa đổi các tiêu chuẩn và quy tắc tính phí liên quan đến dịch vụ đăng ký thành viên. Các tiêu chuẩn và quy tắc đó sẽ có hiệu lực khi phát hành và là một phần có hiệu lực của điều khoản này. Nếu bạn không đồng ý với các tiêu chuẩn, quy tắc và sửa đổi nói trên, bạn có quyền ngừng sử dụng dịch vụ đăng ký thành viên. Nếu bạn tiếp tục sử dụng dịch vụ đăng ký thành viên, bạn được coi là đồng ý với nội dung liên quan.`,
            ]
        },
        {
            'title': '3. Tuyên bố từ chối trách nhiệm',
            'content': [
                `${appName} có quyền xác định một cách độc lập nội dung cụ thể của các dịch vụ đăng ký thành viên dựa trên các kế hoạch và hoạt động sản phẩm, đồng thời có quyền tăng thêm hoặc loại bỏ nội dung điện tử hoặc tạm ngừng hoặc chấm dứt cung cấp một phần dịch vụ của dịch vụ đăng ký thành viên dựa trên tình trạng bản quyền của nội dung điện tử. Quyết định nói trên có hiệu lực mà không cần thông báo và bạn đồng ý rằng ${appName} được miễn trách nhiệm.`,
            ]
        }
    ],
    'en': [
        {
            'title': '',
            'content': [
                `Welcome to open ${appName} VIP and read "${appName} VIP Subscription Service Agreement" If you are under the age of 18, please read this agreement with your legal guardian.`
            ]
        },
        {
            'title': '1. The content of the membership subscription service',
            'content': [
                `After receiving or paying to open the membership subscription service, you can enjoy various exclusive privileges and value-added services within the corresponding service period.`,
                `After opening monthly membership, annual membership, and 1 month membership, the service period you will receive is 31 calendar days, 366 calendar days, and 31 calendar days respectively.`,
            ]
        },
        {
            'title': '2. Regarding paid services',
            'content': [
                `You understand and agree that the membership subscription service is a paid service, and ${appName} will charge a fee for the use of Internet value-added services; you can check the specific term and corresponding fees of the membership subscription service on the relevant service page and choose the type of service yourself. After the membership subscription function is turned on, regardless of whether you actually use the service or access the electronic content within the corresponding service period, the paid fees are not refundable.`,
                `You understand and agree that ${appName} reserves the right to revise the charging standards and rules related to member subscription services from time to time at its sole discretion. Such standards and rules will become effective upon publication and constitute an effective part of these terms. If you do not agree to the aforementioned standards, rules and their amendments, you have the right to stop using the membership subscription service. If you continue to use the membership subscription service, you are deemed to agree to the relevant content.`
            ]
        },
        {
            'title': '3. Disclaimer',
            'content': [
                `${appName} has the right to independently determine the specific content of member subscription services based on product plans and operating conditions, and has the right to add or remove electronic content or suspend or terminate the provision of member subscription services based on the copyright status of the electronic content. The aforementioned decision becomes effective without notice, and you agree that ${appName} is exempt from liability.`
            ]
        }
    ],
};

export { memberData };